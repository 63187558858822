import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   name: 'Market',
  //   component: Market,
  //   meta: { keepAlive: true, usesHeader: true }
  // },
  // {
  //   path: '/gate',
  //   name: 'Login',
  //   component: 'Login',
  //   meta: { keepAlive: false, usesHeader: false }
  // },
  {
    path: "/",
    name: "Default",
    redirect: "/sign"
  },
  {
    path: "/dashboard",
    name: "DashboardPage",
    component: () => import("../views/Dashboard.vue"),
    meta: { keepAlive: true, usesHeader: true },
  },
  {
    path: "/stats",
    name: "StatisticsPage",
    component: () => import("../views/StatisticsPage.vue"),
    meta: { keepAlive: true, usesHeader: true },
  },
  {
    path: "/miners",
    name: "MinersPage",
    component: () => import("../views/Miners.vue"),
    meta: { keepAlive: true, usesHeader: true },
  },
  {
    path: "/wallet",
    name: "WalletPage",
    component: () => import("../views/Wallet.vue"),
    meta: { keepAlive: true, usesHeader: true },
  },
  {
    path: "/cart",
    name: "CartPage",
    component: () => import("../views/Cart.vue"),
    meta: { keepAlive: true, usesHeader: true },
  },
  {
    path: "/settings",
    name: "ProfileSettings",
    component: () => import("../views/ProfileSettings.vue"),
    meta: { keepAlive: true, usesHeader: true },
  },
  {
    path: "/marketplace",
    name: "MarketplacePage",
    component: () => import("../views/MarketplacePage.vue"),
    meta: { keepAlive: true, usesHeader: true },
  },
  {
    path: "/sign",
    name: "SignIn",
    component: () => import("../views/SignIn.vue"),
    meta: { keepAlive: true, usesHeader: false },
  },
  {
    path: "/:catchAll(.*)",
    name: "default",
    redirect: "/sign", // redirect: '/'
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
