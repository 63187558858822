<template>
  <div id="app-container">
    <div v-if="$route.meta.usesHeader" class="sidenav-mobile" v-bind:class="{sidenavActive: sidenavActive}">
      <div class="sidenav-mobile-section">
        <router-link @click.self="toggleSidenav" to="/dashboard" id="buttonDashboard" v-bind:class="{sidenavbuttonactive: $route.name === 'DashboardPage'}" class="sidenav-mobile-unit">
          <div class="sidenav-mobile-unit-left">
            <img @click.self="hideMenu" class="sidenav-mobile-icon" src="./assets/sidenav/dashboard.svg">
            <span class="sidenav-mobile-title">Dashboard</span>
          </div>
          <img @click.prevent="toggleSidenav()" class="burger-close" src="./assets/burger-close.svg">
        </router-link>
      </div>
      <div class="sidenav-mobile-section">
        <router-link @click="toggleSidenav" to="/miners" id="buttonMiners" v-bind:class="{sidenavbuttonactive: $route.name === 'MinersPage'}" class="sidenav-mobile-unit">
          <div class="sidenav-mobile-unit-left">
            <img class="sidenav-mobile-icon" src="./assets/sidenav/gpu.svg">
            <span class="sidenav-mobile-title">Miners</span>
          </div>
          <img class="sidenav-mobile-wedge" src="./assets/sidenav/wedge.svg">
        </router-link>
        <router-link @click="toggleSidenav" to="/stats" id="buttonStatistics" v-bind:class="{sidenavbuttonactive: $route.name === 'StatisticsPage'}" class="sidenav-mobile-unit">
          <div class="sidenav-mobile-unit-left">
            <img class="sidenav-mobile-icon" src="./assets/sidenav/pie.svg">
            <span class="sidenav-mobile-title">Statistics</span>
          </div>
          <img class="sidenav-mobile-wedge" src="./assets/sidenav/wedge.svg">
        </router-link>
        <router-link @click="toggleSidenav" to="/marketplace" id="buttonMarketplace" v-bind:class="{sidenavbuttonactive: $route.name === 'MarketplacePage'}" class="sidenav-mobile-unit">
          <div class="sidenav-mobile-unit-left">
            <img class="sidenav-mobile-icon" src="./assets/sidenav/marketplace.svg">
            <span class="sidenav-mobile-title">Marketplace</span>
          </div>
          <img class="sidenav-mobile-wedge" src="./assets/sidenav/wedge.svg">
        </router-link>
        <router-link @click="toggleSidenav" to="/settings" id="buttonSettings" v-bind:class="{sidenavbuttonactive: $route.name === 'ProfileSettings'}" class="sidenav-mobile-unit">
          <div class="sidenav-mobile-unit-left">
            <img class="sidenav-mobile-icon" src="./assets/sidenav/settings.svg">
            <span class="sidenav-mobile-title">Settings</span>
          </div>
          <img class="sidenav-mobile-wedge" src="./assets/sidenav/wedge.svg">
        </router-link>
        <router-link @click="toggleSidenav" to="/wallet" id="buttonWallet" v-bind:class="{sidenavbuttonactive: $route.name === 'WalletPage'}" class="sidenav-mobile-unit">
          <div class="sidenav-mobile-unit-left">
            <img class="sidenav-mobile-icon" src="./assets/sidenav/wallet.svg">
            <span class="sidenav-mobile-title">Wallet</span>
          </div>
          <img class="sidenav-mobile-wedge" src="./assets/sidenav/wedge.svg">
        </router-link>
      </div>
       <div class="sidenav-mobile-section">
        <router-link @click="toggleSidenav" to="/cart" id="buttonCart" v-bind:class="{sidenavbuttonactive: $route.name === 'CartPage'}" class="sidenav-mobile-unit">
          <div class="sidenav-mobile-unit-left">
            <img class="sidenav-mobile-icon" src="./assets/cart.svg">
            <span class="sidenav-mobile-title">Cart</span>
          </div>
          <img class="sidenav-mobile-wedge" src="./assets/sidenav/wedge.svg">
        </router-link>
        <button @click="toggleSidenav" id="buttonNotifications" class="sidenav-mobile-unit">
          <div class="sidenav-mobile-unit-left">
            <img class="sidenav-mobile-icon" src="./assets/notif.svg">
            <span class="sidenav-mobile-title">Notifications</span>
          </div>
          <img class="sidenav-mobile-wedge" src="./assets/sidenav/wedge.svg">
        </button>
      </div>
      <div class="sidenav-mobile-section">
        <div class="sidenav-mobile-static-row">
          <span class="mobile-row-title">BTC price: </span>
          <span class="mobile-row-value">20 000 USDT<span class="change-positive">+0.59%</span></span>
        </div>
        <div class="sidenav-mobile-static-row">
          <span class="mobile-row-title">Electricity account: </span>
          <span class="mobile-row-value">358 USDT</span>
        </div>
        <div class="sidenav-mobile-static-row">
          <span class="mobile-row-title">Mining account: </span>
          <span class="mobile-row-value">0.035 BTC</span>
        </div>
        <div class="sidenav-mobile-static-row">
          <span class="mobile-row-title">Staking rewards: </span>
          <span class="mobile-row-value">0 WBTC</span>
        </div>
        <button @click="toggleSidenav" id="buttonHelp" v-bind:class="{sidenavbuttonactive: $route.name === 'HelpCenter'}" class="sidenav-mobile-unit">
          <div class="sidenav-mobile-unit-left">
            <img v-if="$route.name === 'HelpCenter'" class="sidenav-mobile-icon" src="./assets/sidenav/help-color.svg">
            <img v-else class="sidenav-mobile-icon" src="./assets/sidenav/help.svg">
            <span class="sidenav-mobile-title">Help center</span>
          </div>
        </button>
      </div>
    </div>
    <div v-if="$route.meta.usesHeader" class="header">
      <div class="header-section">
        <div @click="toggleSidenav()" class="header-burger">

        </div>
        <div class="header-left">
          <router-link to="/sign" class="header-logo"></router-link>
        </div>
        <div class="header-optional">
          <a class="header-optional_bar" href="https://coinmarketcap.com/currencies/bitcoin/" target="_blank">
            <span class="bar-content">BTC price: 20 000 USDT <span class="change-positive">+0.59%</span></span>
          </a>
          <router-link to="/wallet" class="header-optional_bar">
            <span class="bar-content">Electricity account: 358 USDT</span>
          </router-link>
          <router-link to="/wallet" class="header-optional_bar">
            <span class="bar-content">Mining account: 0.035 BTC</span>
          </router-link>
          <router-link to="/wallet" class="header-optional_bar">
            <span class="bar-content">Staking rewards: 0 WBTC</span>
          </router-link>
        </div>
      </div>
      <router-link to="/sign">
        <img src="./assets/logo-mobile.svg" class="mobile-logo">
      </router-link>
      <div class="header-right">
        <router-link v-bind:class="{cartbtnglowing: cart[0]}" to="/cart" class="header-right-btn cartbtn"></router-link>
        <button class="header-right-btn notifbtn"></button>
        <button class="header-right-btn avatarbtn"></button>
        <div class="header-credentials">
          <span class="credentials-name">Austin Robertson</span>
          <span class="credentials-account">0xE8D...19c4</span>
        </div>
      </div>
    </div>
    <div class="main">
      <div v-if="$route.meta.usesHeader" v-bind:class="{sidenavtoggled: sidenavActive}" class="sidenav">
        <div class="sidenav-section">
          <router-link @click.prevent="hideMenu" to="/dashboard" id="buttonDashboard" v-bind:class="{sidenavbuttonactive: $route.name === 'DashboardPage'}" class="sidenav-bordered-button">
            <img src="./assets/sidenav/dashboard.svg">
            <div @click.self.stop.prevent="toggleSidenav()" class="control-btn"/>
            <span class="sidenav-button-title">Dashboard</span>
          </router-link>
        </div>
        <div class="sidenav-section">
          <div class="sidenav-section_title">Pages</div>
          <router-link to="/miners" id="buttonMiners" v-bind:class="{sidenavbuttonactive: $route.name === 'MinersPage'}" class="sidenav-button">
            <div class="sidenav-button-left">
              <img class="sidenav-button-icon" src="./assets/sidenav/gpu.svg">
              <span class="sidenav-button-title">Miners</span>
            </div>
            <img class="sidenav-button-wedge" src="./assets/sidenav/wedge.svg">
          </router-link>
          <router-link to="/stats" id="buttonStatistics" v-bind:class="{sidenavbuttonactive: $route.name === 'StatisticsPage'}" class="sidenav-button">
            <div class="sidenav-button-left">
              <img class="sidenav-button-icon" src="./assets/sidenav/pie.svg">
              <span class="sidenav-button-title">Statistics</span>
            </div>
            <img class="sidenav-button-wedge" src="./assets/sidenav/wedge.svg">
          </router-link>
          <router-link to="/marketplace" id="buttonMarketplace" v-bind:class="{sidenavbuttonactive: $route.name === 'MarketplacePage'}" class="sidenav-button">
            <div class="sidenav-button-left">
              <img class="sidenav-button-icon" src="./assets/sidenav/marketplace.svg">
              <span class="sidenav-button-title">Marketplace</span>
            </div>
            <img class="sidenav-button-wedge" src="./assets/sidenav/wedge.svg">
          </router-link>
          <router-link to="/settings" id="buttonSettings" v-bind:class="{sidenavbuttonactive: $route.name === 'ProfileSettings'}" class="sidenav-button">
            <div class="sidenav-button-left">
              <img class="sidenav-button-icon" src="./assets/sidenav/settings.svg">
              <span class="sidenav-button-title">Settings</span>
            </div>
            <img class="sidenav-button-wedge" src="./assets/sidenav/wedge.svg">
          </router-link>
          <router-link to="/wallet" id="buttonWallet" v-bind:class="{sidenavbuttonactive: $route.name === 'WalletPage'}" class="sidenav-button">
            <div class="sidenav-button-left">
              <img class="sidenav-button-icon" src="./assets/sidenav/wallet.svg">
              <span class="sidenav-button-title">Wallet</span>
            </div>
            <img class="sidenav-button-wedge" src="./assets/sidenav/wedge.svg">
          </router-link>
        </div>
        <div class="sidenav-section">
          <span class="sidenav-section_title"></span>
          <button id="buttonHelp" v-bind:class="{sidenavbuttonactive: $route.name === 'HelpCenter'}" class="sidenav-button">
            <div class="sidenav-button-left">
              <img class="sidenav-button-icon" src="./assets/sidenav/help.svg">
              <span class="sidenav-button-title">Help center</span>
            </div>
          </button>
        </div>
      </div>
      <router-view/>
    </div>
  </div>
</template>

<script>
import store from './store/index.js'
import unprocessedMiners from './fixtures/mockData.json'
import unprocessedNfts from './fixtures/mockMiners.json'

export default {
  name: 'App',
  store,
  components: {
  },
  data () {
    return {
      sidenavActive: true
    }
  },
  created() {
    unprocessedMiners.forEach(x => {
      this.$store.commit('ADD_TO_SALE', x)
    })
    unprocessedNfts.forEach(x => {
      this.$store.commit('ADD_TO_OWNED', x)
    })
  },
  computed: {
    cart: function() {
      return this.$store.state.cartItems
    }
  },
  methods: {
    hideMenu () {
      this.sidenavActive = false
    },
    toggleSidenav () {
      this.sidenavActive = !this.sidenavActive
    }
  }
}
</script>

<style>
.chart-tooltip {
    padding: 14px 8px;
    font-family: 'General Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 18.9px;
    color: #5B5B5B;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-image: radial-gradient(circle at 100% 100%, transparent 7px, #99e0da 7px, #99e0da 10px, transparent 9px), linear-gradient(to right, #99e0da, #c8e4c8), radial-gradient(circle at 0% 100%, transparent 7px, #c8e4c8 7px, #c8e4c8 10px, transparent 9px), linear-gradient(to bottom, #c8e4c8, #feeab4), radial-gradient(circle at 0% 0%, transparent 7px, #feeab4 7px, #feeab4 10px, transparent 9px), linear-gradient(to left, #feeab4, #a2e1d6), radial-gradient(circle at 100% 0%, transparent 7px, #a2e1d6 7px, #a2e1d6 10px, transparent 9px), linear-gradient(to top, #a2e1d6, #99e0da); background-size: 8px 8px, calc(100% - 14px) 1px, 8px 8px, 1px calc(100% - 14px); background-position: top left,top center,top right,center right, bottom right,bottom center,bottom left,center left; background-repeat: no-repeat;
}

.chart-tooltip-title {
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 5px;
  color: #5B5B5B;
  align-self: center;
}
.chart-tooltip-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #5B5B5B;
  margin-top: 5px;
}
.chart-tooltip-black {
  color: #5B5B5B;
}
.chart-tooltip-subvalue {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #B6BCC4;
}
.chart-tooltip-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 6px;
}
.chart-marker-1, .chart-marker-2, .chart-marker-3, .chart-marker-4 {
  width: 13px;
  height: 13px;
  border-radius: 50%;

}
.chart-marker-1 { background-color: #99E0DA; }
.chart-marker-2 { background-color: #57B8BE; }
.chart-marker-3 { background-color: #18888F; }
.chart-marker-4 { background-color: #00565B; }

.apexcharts-xcrosshairs {
  stroke-linecap: initial;
  stroke-width: 0;
  stroke-dasharray: 0;
  stroke: transparent !important;
}
* {
  margin: 0; padding: 0;
}

a {
  text-decoration: none;
}

.sidenav-mobile {
  display: none;
}

button {
  cursor: pointer;
  background: 0;
  border: 0;
}

#app {
  font-family: 'General Sans', sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height:100vh;
  overflow: hidden;
}

#app-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 1920px;
  min-height:100vh;
  margin: 0px auto;
  display: inline-block;
  overflow: visible;
}

.header {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  padding: 6px 24px;
  border-bottom: 1px solid #E9ECEF;
}

.header-burger {
  background-image: url('./assets/burger.svg');
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
  filter: drop-shadow(0px 2.66667px 2.66667px rgba(0, 0, 0, 0.05));
}

.header-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.header-left, .header-optional, .header-right {
  display: flex;
  flex-direction: row;
}

.header-left {
  justify-content: space-between;
  align-items: center;
  margin-right: 48px;
}

.header-logo {
  margin-left: 14px;
  margin-top: 4px;
  width: 96.02px;
  height: 31px;
  background-image: url('./assets/logo-desktop.svg');
  background-size: cover;
  margin-right: 32px;
}

.mobile-logo {
  display: none;
}

.header-optional {
  justify-content: space-between;
  align-items: center;
}

.header-optional_bar {
  border: 1px solid #E9ECEF;
  box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
  padding: 8px 28px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.header-optional_bar:hover {
  transition: 0.2s;
  box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.15);
}

.bar-content {
  transition: 0.2s;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: rgba(158, 166, 177, 0.75);
}

.change-positive {
  padding-left: 4px;
  color: #70D7D2;
}

.header-optional_bar:hover .bar-content {
  transition: 0.2s;
  color: rgba(158, 166, 177, 0.95);
}

.header-right {
  justify-content: space-between;
  align-items: center;
}

.header-right-btn {
  transition: 0.3s;
  opacity: 0.85;
}

.header-right-btn:hover {
  transition: 0.3s;
  opacity: 1;
}

.cartbtn {
  width: 30px;
  height: 27px;
  background-image: url('./assets/cart.png');
  background-size: cover;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}

.cartbtnglowing:after {
  content: "";
  width: 19px;
  height: 19px;
  top: -8px;
  right: -8px;
  background-image: url('./assets/glow.svg');
  background-size: cover;
  position: absolute;
}

.notifbtn {
  width: 24px;
  height: 28px;
  background-image: url('./assets/notif.png');
  background-size: cover;
  margin-left: 15px;
  margin-right: 15px;
}

.avatarbtn {
  width: 45px;
  height: 45px;
  background-image: url('./assets/avatar-icon.png');
  background-size: cover;
  margin-left: 15px;
  margin-right: 15px;
}

.header-credentials {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.credentials-name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 175%;
  color: #232D42;
}

.credentials-account {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #8A92A6;
}

.main {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  position: relative;
}

.sidenav {
  transition: max-width 0.5s ease;
  transition: padding 0.7s ease;
  visibility: hidden;
  max-width: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidenav.sidenavtoggled {
  transition: max-width 0.5s ease;
  transition: padding 0.7s ease;
  visibility: visible;
  max-width: 258px;
  padding-left: 26px;
  padding-right: 26px;
  background-image: url('./assets/sidenav/gfx-sidebar.svg');
  background-position: 0px 100%;
  background-repeat: no-repeat;
}

.sidenavHidden {
  display: none;
}

.sidenav-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.sidenav-section:last-child {
  margin-top: 36px;
}

.sidenav-section_title {
  padding-left: 14px;
  border-bottom: 1px solid #E9ECEF;
  width: 100%;
  text-align: left;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  color: #ADB5BD;
}

.sidenav .sidenav-bordered-button {
  opacity: 0;
  transition: opacity 0s;
  position: static;
}

.sidenav.sidenavtoggled .sidenav-bordered-button {
  opacity: 1;
  transition: opacity 0.3s ease-out 0.2s;
  position: relative;
}

.sidenav-bordered-button {
  margin-top: 20px;
  margin-bottom: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: radial-gradient(circle at 100% 100%, transparent 7px, #99e0da 7px, #99e0da 8px, transparent 8px), linear-gradient(to right, #99e0da, #c8e4c8), radial-gradient(circle at 0% 100%, transparent 7px, #c8e4c8 7px, #c8e4c8 8px, transparent 8px), linear-gradient(to bottom, #c8e4c8, #feeab4), radial-gradient(circle at 0% 0%, transparent 7px, #feeab4 7px, #feeab4 8px, transparent 8px), linear-gradient(to left, #feeab4, #a2e1d6), radial-gradient(circle at 100% 0%, transparent 7px, #a2e1d6 7px, #a2e1d6 8px, transparent 8px), linear-gradient(to top, #a2e1d6, #99e0da); background-size: 8px 8px, calc(100% - 16px) 1px, 8px 8px, 1px calc(100% - 16px); background-position: top left,top center,top right,center right, bottom right,bottom center,bottom left,center left; background-repeat: no-repeat;
  padding: 12px 42px;
}

.control-btn {
  outline: 0;
  position: absolute;
  width: 30px;
  height: 31px;
  right: -40px;
  background-image: url('./assets/burger-close.svg');
  background-size: cover;
  z-index: 100;
}

.sidenavControlbtnReversed {
  position: absolute;
  background-image: url('./assets/open.png');
  background-size: cover;
  right: none;
  width: 30px;
  height: 31px;
  cursor: pointer;
  left: 0;
  top: 24px;
}

.sidenav-bordered-button img {
  margin-right: 12px;
}

.sidenav .sidenav-button {
  opacity: 0;
  transition: opacity 0s;
}

.sidenav.sidenavtoggled .sidenav-button {
  opacity: 1;
  transition: opacity 0.3s ease-out 0.2s;
}

.sidenav-button {
  transition: 0.2s;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.sidenav-button:hover, .sidenavbuttonactive {
  transition: 0.2s;
  background: linear-gradient(124.4deg, #99E0DA 34.32%, #FEEAB4 87.34%);
  border-radius: 4px;
}
#buttonDashboard {
  border-radius: 8px;
}

/* .sidenavbuttonactive#buttonMiners .sidenav-button-icon, .sidenav-button#buttonMiners:hover .sidenav-button-icon {
  content: url('./assets/sidenav/gpu-color.svg');
}

.sidenavbuttonactive#buttonStatistics .sidenav-button-icon, .sidenav-button#buttonStatistics:hover .sidenav-button-icon {
  content: url('./assets/sidenav/pie-color.svg');
}

.sidenavbuttonactive#buttonMarketplace .sidenav-button-icon, .sidenav-button#buttonMarketplace:hover .sidenav-button-icon {
  content: url('./assets/sidenav/marketplace-color.svg');
}

.sidenavbuttonactive#buttonSettings .sidenav-button-icon, .sidenav-button#buttonSettings:hover .sidenav-button-icon {
  content: url('./assets/sidenav/settings-color.svg');
}

.sidenavbuttonactive#buttonWallet .sidenav-button-icon, .sidenav-button#buttonWallet:hover .sidenav-button-icon {
  content: url('./assets/sidenav/wallet-color.svg');
}

.sidenavbuttonactive#buttonHelp .sidenav-button-icon, .sidenav-button#buttonHelp:hover .sidenav-button-icon {
  content: url('./assets/sidenav/help-color.svg');
}

.sidenavbuttonactive .sidenav-button-wedge, .sidenav-button:hover .sidenav-button-wedge {
  content: url('./assets/sidenav/wedge-color.svg');
} */

/*.sidenav-button:hover .sidenav-button-icon, .sidenavbuttonactive .sidenav-button-icon {
  content: url('./assets/sidenav/dashboard-color.svg')
}*/

/*.sidenav-button:hover .sidenav-button-title, .sidenavbuttonactive .sidenav-button-title {
  transition: 0.2s;
  background: linear-gradient(124.4deg, #99E0DA 34.32%, #FEEAB4 87.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
*/
.sidenav-button-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.sidenav-button-icon {
  margin-right: 16px;
  width: 24px;
}

.sidenav-button-title {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  color: #5B5B5B;
}

@media screen and (max-width: 1820px) {
  .bar-content {
    font-size: 14px;
  }
}

@media screen and (max-width: 1620px) {
  .header-optional_bar {
    border: 1px solid #E9ECEF;
    box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    padding: 8px 12px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1500px) {
  .header-optional_bar {
    border: 1px solid #E9ECEF;
    box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    padding: 8px 8px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1632px) {
  .header-left {
    margin-right: -12px;
  }

  .header-burger {
    width: 20px;
    height: 20px;
  }


  .header-optional_bar {
    padding: 2px 8px;
    margin-right: 12px;
  }

  .bar-content {
    font-size: 12px;
  }

  .cartbtn {
    width: 20px;
    height: 18px;
    background-image: url('./assets/cart.svg')
  }

  .cartbtnglowing:after {
    width: 12px;
    height: 12px;
    top: -4px;
    right: -4px;
  }

  .notifbtn {
    width: 15px;
    height: 18px;
    background-image: url('./assets/notif.svg')
  }

  .avatarbtn {
    width: 32px;
    height: 32px;
  }

  .sidenav.sidenavtoggled {
    padding-left: 16px;
    padding-right: 16px;
  }

  .sidenav-section_title {
    font-size: 12px;
  }

  .sidenav-bordered-button {
    padding: 8px 24px;
  }

  .sidenav-bordered-button img {
    width: 14px;
    height: 14px;
  }

  .sidenav-button {
    padding: 5px;
  }

  .sidenav-button-icon {
    width: 14px;
    margin-right: 10px;
  }

  .sidenav-button-title {
    font-size: 12px;
  }

  .sidenav-section:last-child {
    margin-top: 16px;
  }

  .control-btn {
    width: 20px;
    height: 20px;
    right: -26px;
  }
}

@media screen and (max-width: 1020px) {
  .header-optional {
    display: none;
  }

  .header-burger {
    background-image: url('./assets/burger.svg');
    background-size: cover;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .header-logo {
    display: none;
  }

  .mobile-logo {
    display: block;
  }

  .cartbtn, .notifbtn, .header-credentials {
    display: none;
  }

  .avatarbtn {
    margin-right: 0;
    width: 30px;
    height: 30px;
  }

  .sidenav {
    display: none;
  }

  .burger-close {
    width: 30px;
    height: 30px;
  }

  .header-left {
    margin-right: 0;
  }

  .sidenavControlbtnReversed {
    display: none;
  }

  .sidenav-mobile {
    z-index: 10000;
    top: 0;
    left: 0;
    position: absolute;
    display: none;
    width: 0;
    height: auto;
    background: #ffffff;
    padding: 12px;
    padding-left: 12px;
    padding-top: 8px;
    border-radius: 16px;
    transition: 0.3s;
    -webkit-box-shadow: 11px 12px 17px 0px rgba(34, 60, 80, 0.1);
    -moz-box-shadow: 11px 12px 17px 0px rgba(34, 60, 80, 0.1);
    box-shadow: 11px 12px 17px 0px rgba(34, 60, 80, 0.1);
  }

  .sidenavActive {
    width: 248px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.3s;
  }

  .burger-close {
    position: absolute;
    right: -32px;
    top: 5px;
  }

  .sidenav-mobile-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid #ADB5BD;
  }

  .sidenav-mobile-section:last-child {
    border: 0;
    padding-top: 8px;
  }

  .sidenav-mobile-unit {
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
  }

  .sidenav-mobile-unit:hover, .sidenavbuttonactive {
    background: linear-gradient(124.4deg, #99E0DA 34.32%, #FEEAB4 87.34%);
    border-radius: 4px;

  }

  /*.sidenav-mobile-unit:hover .sidenav-mobile-title, .sidenavbuttonactive .sidenav-mobile-title {
    background: linear-gradient(124.4deg, #99E0DA 34.32%, #FEEAB4 87.34%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }*/

  .sidenav-mobile-unit-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /*.sidenavbuttonactive#buttonDashboard .sidenav-mobile-icon, .sidenav-mobile-unit#buttonDashboard:hover .sidenav-mobile-icon {
    content: url('./assets/sidenav/dashboard-color.svg');
  }

  .sidenavbuttonactive#buttonMiners .sidenav-mobile-icon, .sidenav-mobile-unit#buttonMiners:hover .sidenav-mobile-icon {
    content: url('./assets/sidenav/gpu-color.svg');
  }

  .sidenavbuttonactive#buttonStatistics .sidenav-mobile-icon, .sidenav-mobile-unit#buttonStatistics:hover .sidenav-mobile-icon {
    content: url('./assets/sidenav/pie-color.svg');
  }

  .sidenavbuttonactive#buttonMarketplace .sidenav-mobile-icon, .sidenav-mobile-unit#buttonMarketplace:hover .sidenav-mobile-icon {
    content: url('./assets/sidenav/marketplace-color.svg');
  }

  .sidenavbuttonactive#buttonSettings .sidenav-mobile-icon, .sidenav-mobile-unit#buttonSettings:hover .sidenav-mobile-icon {
    content: url('./assets/sidenav/settings-color.svg');
  }

  .sidenavbuttonactive#buttonWallet .sidenav-mobile-icon, .sidenav-mobile-unit#buttonWallet:hover .sidenav-mobile-icon {
    content: url('./assets/sidenav/wallet-color.svg');
  }

  .sidenavbuttonactive#buttonHelp .sidenav-mobile-icon, .sidenav-mobile-unit#buttonHelp:hover .sidenav-mobile-icon {
    content: url('./assets/sidenav/help-color.svg');
  }

  .sidenavbuttonactive .sidenav-mobile-wedge, .sidenav-mobile-unit:hover .sidenav-mobile-wedge {
    content: url('./assets/sidenav/wedge-color.svg');
  }*/

  .sidenav-mobile-icon {
    margin-right: 14px;
    width: 24px;
  }

  .sidenav-mobile-title {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 175%;
    color: #5B5B5B;
  }

  .sidenav-mobile-static-row {
    padding-left: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }

  .mobile-row-title {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: rgba(158, 166, 177, 0.75);
    margin-right: 4px;
  }

  .mobile-row-value {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: rgba(158, 166, 177, 0.75);
  }
}
</style>
