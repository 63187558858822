import { createStore } from 'vuex'

export default createStore({
  state: {
    cartItems: [],
    marketplaceItems: [],
    ownedItems: []
  },
  getters: {
  },
  mutations: {
    ADD_TO_CART(state, item)
    {
        state.cartItems.push(item)
    },
    REMOVE_FROM_CART(state, item)
    {
      const removed = state.cartItems.find(x => x.id === item)
      removed.selected = ''
      removed.shown = ''
      removed.page = 'details'
      state.cartItems = state.cartItems.filter(x => x.id !== item)
    },
    REMOVE_CLEAR(state) {
      state.cartItems = []
    },
    ADD_TO_OWNED(state, item) // purchase
    {
      state.ownedItems.push(item)
    },
    ADD_TO_SALE(state, item) // list for sale
    {
      state.marketplaceItems.push(item)
    },
    REMOVE_FROM_OWNED(state, item) // sell
    {
      // const removed = state.ownedItems.find(x => x.id === item)
      state.ownedItems = state.ownedItems.filter(x => x.id !== item)
    },
    REMOVE_FROM_SALE(state, item) // unlist off sale
    {
      state.marketplaceItems = state.marketplaceItems.filter(x => x.id !== item)
    }
  },
  actions: {
  },
  modules: {
  }
})
